import React, { memo } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";

// Hooks
import useStyles from "@hooks/useStyles";
import withStyles from "@libs/framework/src/libs/withStyles";

// MUI Icons
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { styles } from "./.config";

const { SITE_NAME } = process.env;

const HourlyFinalPrice = compose(
	withStyles(styles),
	memo
)(({ total, unit, description, isMobile }) => {
	const classes = useStyles("FinalPrice");

	//const displayChatBubble = unit === unitTypes.contactForPrice || !unit;
	const displayChatBubble = !total;
	const displayUnitSpan = !!unit;
	const subHeader = description;

	return isMobile ? (
		<div className={`${classes.proPrice} ${classes.proPriceMobile}`}>
			{displayChatBubble && (
				<ChatBubbleOutlineOutlinedIcon className={`${classes.proIcon}`} />
			)}

			{!!total && (
				<span className={classes.proPriceAmountMobile}>${total}</span>
			)}
			{displayUnitSpan && <span>{unit}</span>}

			<span className={`${classes.proPriceText} ${classes.proPriceTextMobile}`}>
				{subHeader}
			</span>
		</div>
	) : (
		<div className={classes.proPriceBox}>
			{displayChatBubble && (
				<div className={classes.proIcon}>
					<ChatBubbleOutlineOutlinedIcon />
				</div>
			)}
			<div className={classes.proPrice}>
				{!!total && <span className={classes.proPriceAmount}>${total}</span>}
				{displayUnitSpan && <span>{unit}</span>}
			</div>

			<span className={classes.proPriceText}>{subHeader}</span>
		</div>
	);
});

HourlyFinalPrice.propTypes = {
	total: PropTypes.number,
	unit: PropTypes.string,
	description: PropTypes.string,
	isMobile: PropTypes.bool,
};

export default HourlyFinalPrice;
