export default (theme) => ({
	proBox: {
		"display": "flex",
		"flexDirection": "column",
		"textAlign": "left",
		"paddingTop": 30,
		"paddingBottom": 30,
		"borderBottom": "1px solid rgba(0,0,0,0.1)",
		"&:hover $proName": {
			color: theme.palette.primary.main,
		},
		"&:hover": {
			backgroundColor: "#FAFAFA",
		},
		[theme.breakpoints.down("xs")]: {
			paddingTop: 24,
			paddingBottom: 24,
		},
	},
	proBoxFlex: {
		display: "flex",
	},
	proImg: {
		height: 200,
		width: 200,
		[theme.breakpoints.down("md")]: {
			height: 180,
			width: 180,
		},
		[theme.breakpoints.down("sm")]: {
			height: 140,
			width: 140,
		},
		[theme.breakpoints.down("xs")]: {
			height: 84,
			width: 84,
		},
	},
	proNameBox: {
		textAlign: "left",
		width: "100%",
	},
	proName: {
		"display": "-webkit-box",
		"overflow": "hidden",
		"margin": "0px 0px 4px 0px",
		"color": theme.palette.secondary.main,
		"fontSize": 18,
		"fontWeight": 700,
		"lineHeight": "24px",
		"textTransform": "capitalize",
		"wordBreak": "break-word",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": 2,
		"&:hover": {
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: 16,
			lineHeight: "20px",
		},
	},
	proNameCount: {
		fontWeight: 400,
	},
	proContent: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		flexWrap: "nowrap",
		overflow: "hidden",
		justifyContent: "start",
		alignItems: "stretch",
		alignContent: "center",
		maxWidth: 660,
		paddingLeft: 16,
		paddingRight: 20,
		[theme.breakpoints.down("xs")]: {
			paddingRight: 0,
		},
	},
	proReviews: {
		display: "flex",
		alignItems: "center",
		//marginBottom: 4,
	},
	proReviewsAvg: {
		fontSize: 14,
		fontWeight: 700,
		marginRight: 4,
		marginTop: 2,
		color: "#ff6474",
	},
	proReviewsStatus: {
		fontSize: 14,
		fontWeight: 600,
		marginRight: 4,
		marginLeft: 6,
	},
	proReviewsCount: {
		fontSize: 14,
		color: theme.palette.grey[600],
		marginLeft: 4,
	},
	proFacts: {
		marginBottom: 4,
		[theme.breakpoints.down("xs")]: {
			marginBottom: 0,
		},
	},
	proFacts6: {
		marginTop: 4,
		marginBottom: 4,
	},
	proFactsB: {
		paddingLeft: 8,
		paddingRight: 8,
	},
	proFactsIcon: {
		marginRight: 4,
	},
	proFactsHiddenMobile: {
		display: "block",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	proFactsBox: {
		display: "flex",
		alignItems: "center",
		//color: theme.palette.grey[600],
		fontSize: 14,
	},
	proFactsText: {
		marginLeft: 4,
	},
	proFactsTextB: {
		fontWeight: 600,
	},
	proAboutBox: {
		overflow: "hidden",
		marginTop: 4,
	},
	proAbout: {
		"display": "-webkit-box",
		"-webkit-box-orient": "vertical",
		"-webkit-line-clamp": 2,
		"fontSize": 14,
		"lineHeight": 1.5,
		"maxHeight": 40,
		"maxWidth": 550,
		"overflow": "hidden",
		"textOverflow": "ellipsis",
		"color": "#6e6e6e",
		[theme.breakpoints.down("xs")]: {
			"fontSize": 13,
			"-webkit-line-clamp": 3,
			"maxHeight": 60,
		},
	},
	proAboutMessage: {
		overflow: "hidden",
		backgroundColor: "#FAFAFA",
		borderRadius: 4,
		padding: 8,
	},
	proAboutLink: {
		color: theme.palette.primary.main,
	},
	proPriceWrapper: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "space-between",
		flexDirection: "column",
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	proPriceWrapperMobile: {
		display: "flex",
		alignItems: "center",
		marginTop: 8,
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
	},
	proPriceBox: {
		textAlign: "right",
	},
	proPrice: {
		whiteSpace: "nowrap",
	},
	proPriceMobile: {
		fontSize: 14,
		display: "flex",
		alignItems: "center",
	},
	proPriceAmount: {
		fontSize: 18,
		fontWeight: 600,
		paddingRight: 4,
	},
	proPriceAmountMobile: {
		fontWeight: 600,
		marginRight: 4,
	},
	proPriceText: {
		fontSize: 12,
		fontWeight: 300,
		color: theme.palette.grey[700],
	},
	proPriceTextMobile: {
		marginLeft: 8,
	},
	proIcon: {
		color: theme.palette.secondary.main,
		[theme.breakpoints.down("xs")]: {
			fontSize: 18,
		},
	},
	flexBasis: {
		flexBasis: "49%",
	},
	proButtons: {
		display: "flex",
		flexDirection: "column",
	},
	proButtonsSpacing: {
		marginBottom: 12,
	},
	proButtonsMobile: {
		display: "flex",
		justifyContent: "space-between",
		flexDirection: "row",
		marginTop: 12,
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
	},
	proBadges: {
		display: "flex",
		justifyContent: "flex-start",
		flexWrap: "wrap",
		marginTop: 4,
	},
	profileChip: {
		display: "flex",
		alignItems: "center",
		marginTop: 4,
		marginRight: 6,
		marginBottom: 4,
	},
	chipPurple: {
		color: "#0535bc",
		backgroundColor: "#e7eaff",
		cursor: "pointer",
	},
	iconPurple: {
		color: "#0535bc",
	},
	chipGreen: {
		color: "#0d8d33",
		backgroundColor: "#e2f9e9",
		cursor: "pointer",
	},
	iconGreen: {
		color: "#0d8d33",
	},
	chipBlue: {
		color: "#0b05bc",
		backgroundColor: "#e7f4ff",
		cursor: "pointer",
	},
	iconBlue: {
		color: "#0b05bc",
	},
	chipGrey: {
		color: "#455a64",
		backgroundColor: "#eceff1",
		cursor: "pointer",
	},
	iconGrey: {
		color: "#455a64",
	},
	chipYellow: {
		color: "#9e7431",
		backgroundColor: "#fff3e0",
		cursor: "pointer",
	},
	iconYellow: {
		color: "#9e7431",
	},
	chipRed: {
		color: "#b71c1c",
		backgroundColor: "#ffebee",
		cursor: "pointer",
	},
	iconRed: {
		color: "#b71c1c",
	},
});
